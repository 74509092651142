import BaseHttpRepository from '@root/application/infra/datasource/BaseHttpRepository'
import {hookRunner} from '@root/application/service/repository/hookRunner'

class PaymentRepository extends BaseHttpRepository {
  async markAsPaid(paymentId, propertyId, userId, document) {
    const resp = await this._api.post('payment/pay', {
      payment_id: paymentId,
      property_id: propertyId,
      user_id: userId,
      document,
    })
    return resp
  }

  /**
   * @param {string} propertyId
   * @param {string} userId
   * @param {{id:string payment_id:string, amount:number, description:string, due_date:string, is_silent:boolean}} updateObj
   * @return {Promise<*>}
   */
  async changePayment(propertyId, userId, updateObj) {
    let payId = ''
    if (updateObj.id) {
      payId = updateObj.id
    } else if (updateObj.payment_id) {
      payId = updateObj.payment_id
    }
    return this._api.post('/payment/change', {
      ...updateObj,
      payment_id: payId,
      user_id: userId,
      property_id: propertyId,
    })
  }

  /**
   * Lista os pagamentos agrupados por mes
   *
   * @param userId
   * @return {Promise<*>}
   */
  async listMonth(userId) {
    const resp = await this._api.get('payment/list/month', {user_id: userId})
    return resp
  }

  /**
   * Lista de pagamentos do imovel
   *
   * @param propertyId
   * @return {Promise<*>}
   */
  async list(propertyId) {
    const resp = await this._api.get('payment/list', {property_id: propertyId})
    return resp
  }

  /**
   * Calculo de juros e multa com nova data de vencimento
   *
   * @param {string} propertyId
   * @param {string} paymentId
   * @param {Date} dueDate
   * @param {number} amount decimal NAO em centavos
   * @param {string} applyTaxes yes | no
   * @return {Promise<*>}
   */
  async calcFineInterest(propertyId, paymentId, dueDate, amount, applyTaxes) {
    const resp = await this._api.get('payment/fine-interest', {
      property_id: propertyId,
      payment_id: paymentId,
      due: dueDate.toISOString(),
      amount,
      apply_taxes: applyTaxes,
    })
    return resp
  }
}

export const paymentRepoInstance = new PaymentRepository()
export const useBankRepoListMonth = hookRunner(paymentRepoInstance, 'listMonth', [])
export const usePaymentsList = hookRunner(paymentRepoInstance, 'list', [])
