import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import {proposalRepoFireInstance} from '@root/application/service/repository/proposal/ProposalRepositoryFirestore'
import ModalShareInvite from '@root/components/App/Cadastro/cadastro-components/Invite/ModalShareInvite'
import useShareInvite from '@root/components/App/Cadastro/cadastro-components/Invite/useShareInvite'
import FormItem from '@root/components/FormHelper/FormItem'
import {HeaderButton} from '@root/components/HeaderButton/HeaderButton'
import IconPlus from '@root/components/icons/IconPlus'
import TextBlock from '@root/components/Layout/Typography/TextBlock'
import BaseModal from '@root/components/Modal/BaseModal'
import Page from '@root/components/Page'
import {ProposalsListItem} from '@root/components/ProposalsListItem/ProposalsListItem'
import {useQuery} from '@root/hooks/urlHook'
import actionDispatcher from '@root/lib/actionDispatcher'
import {creditAnalysisModesOptions} from '@root/lib/predefinedValuesOptions'
import {validateRequired} from '@root/lib/simpleValidators'
import sleep from '@root/lib/sleep'
import {setNavbarTitleAction} from '@root/redux/application/application.actions'
import {setGlobalLoaderAction} from '@root/redux/loader/loader.actions'
import routes from '@root/utils/routes'
import {captureException} from '@sentry/react'
import React, {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'

//TODO: interesteds deve puxar os interessados da proposta

const ProposalList = () => {
  const history = useHistory()
  const {hasParam} = useQuery()
  const inviteShareWithAnalysis = useShareInvite()
  const inviteShareNoAnalysis = useShareInvite()
  const {control, errors, reset, handleSubmit} = useForm()

  const property = useSelector((s) => s.property.data)
  const selectedProposalId = property.selected_proposal?.uid

  const [isRegisterInterestedOpen, setRegisterInterestedVisibility] = useState(hasParam('create'))
  const [proposalsEntries, setProposalsEntries] = useState([])
  const hasProposals = proposalsEntries.length > 0

  const actions = actionDispatcher({
    setGlobalLoaderAction,
    setNavbarTitleAction,
  })

  const shareInviteGlobal = inviteShareWithAnalysis.getTenantGlobalWithAnalysis(property.uid)
  const shareInviteGroup = inviteShareWithAnalysis.getTenantGlobalNoAnalysis(property.uid)

  const openRegisterInterested = () => setRegisterInterestedVisibility(true)
  const closeRegisterInterested = () => setRegisterInterestedVisibility(false)

  const createProposal = async (form) => {
    actions.setGlobalLoaderAction(true)
    try {
      const {name, proposal_type} = form
      const createdProposal = await proposalRepoFireInstance.createEmpty(property.uid, proposal_type, name)

      // const routePath = routeWithParams(routes.DASHBOARD_CREDIT_ANALYSIS, createdProposal.id)
      const routePath = `${routes.DASHBOARD_PROPOSALS_REGISTER}?proposal=${createdProposal.id}`

      await sleep(600)
      history.push(routePath)
    } catch (ex) {
      captureException(ex)
    } finally {
      actions.setGlobalLoaderAction(false)
    }
  }

  useEffect(() => {
    actions.setNavbarTitleAction('Propostas')
    ;(async function () {
      actions.setGlobalLoaderAction(true)
      const loadedProposals = await proposalRepoFireInstance.loadAll(property.proposals)
      const sortedProposals = proposalRepoFireInstance.sort(loadedProposals)
      const sortedNoData = sortedProposals.map(([id, proposal]) => [
        id,
        {
          ...proposal,
          interesteds: proposal.interesteds.map((user) => user.data),
        },
      ])

      setProposalsEntries(sortedNoData)
      actions.setGlobalLoaderAction(false)
    })()
  }, [])

  return (
    // <PropertyRegisterWrapper isPropertyRegistered={property.uid} match={match} onRegisterFinish={onFinish}>
    <Page>
      <Grid container direction="row">
        <Grid container item spacing={2}>
          <Grid item xs={12} sm={12} md={4}>
            <HeaderButton
              icon={IconPlus}
              size={12}
              label="Já tenho os dados do interessado"
              description="Utilize esta opção caso já tenha os dados do interessado em mãos."
              onClick={openRegisterInterested}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <HeaderButton
              icon={CheckBoxOutlineBlankIcon}
              size={12}
              label="Sem analise de crédito"
              description="Cadastro simplificado sem a necessidade de declarar renda."
              onClick={inviteShareNoAnalysis.open}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <HeaderButton
              icon={TrendingUpIcon}
              size={12}
              label="Com análise de crédito"
              description="Opção padrão da plataforma onde pedimos a renda do interessado para gerar análise de crédito."
              onClick={inviteShareWithAnalysis.open}
            />
          </Grid>
        </Grid>

        <Grid container item direction="column" xs={12}>
          <br />
          <br />
          <Typography variant="body2" align="center" style={{marginBottom: 20}}>
            Essas são as propostas recebidas. Você pode acessar as informações e gerar a análise de cada uma delas.
            <br />
            Depois de analisar todas as informações você poderá decidir com qual proposta deseja prosseguir.
          </Typography>
          {hasProposals &&
            proposalsEntries.map(([proposalId, proposal], idx) => (
              <ProposalsListItem
                key={proposalId}
                id={proposalId}
                index={idx}
                selectedProposalId={selectedProposalId}
                proposalName={proposal.name}
                interesteds={proposal.interesteds}
              />
            ))}
        </Grid>
      </Grid>

      <BaseModal
        isOpen={isRegisterInterestedOpen}
        cancelAction={closeRegisterInterested}
        okAction={handleSubmit(createProposal)}
        alignCenter={false}
        size="md"
        cancelLabel="cancelar"
        okLabel="Criar"
        title="Criar proposta">
        <Grid container direction="column">
          <Grid item xs={12} sm={12}>
            <TextBlock>
              Uma proposta pode ter um ou mais moradores e todas as pessoas que forem assinar o contrato deverão estar
              cadastradas na mesma proposta.
            </TextBlock>
          </Grid>

          <Grid item xs={12} md={8}>
            <FormItem
              control={control}
              errors={errors}
              fitContainer
              optional
              name="name"
              label="Identificador"
              placeholder="Nome que ajude a indentificar a proposta, pode ser deixado em branco"
            />
          </Grid>

          <Grid item>
            <FormItem
              control={control}
              errors={errors}
              type="radio"
              name="proposal_type"
              label="Tipo do convite"
              rules={{validate: validateRequired}}
              options={creditAnalysisModesOptions}
            />
          </Grid>
        </Grid>
      </BaseModal>

      <ModalShareInvite inviteShare={inviteShareWithAnalysis} shareLink={shareInviteGlobal} />
      <ModalShareInvite inviteShare={inviteShareNoAnalysis} shareLink={shareInviteGroup} />
    </Page>
  )
}

export default ProposalList
